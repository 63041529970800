.filter-dropdown-container {
  width: 300px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  font-family: "cutomFont" !important;
}

.filter-dropdown-header {
  padding: 15px;
  border-bottom: 1px solid #eee;
}

.filter-dropdown-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.filter-dropdown-body {
  max-height: 400px;
  overflow-y: auto;
  padding: 15px;
}

.filter-section {
  margin-bottom: 20px;
}

.filter-section-title {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 14px;
}

.filter-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.filter-option {
  margin-bottom: 8px;
}

.filter-dropdown-footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #eee;
  gap: 10px;
}

.filter-apply-btn {
  background-color: #1890ff;
  color: white;
  border: none;
  padding: 6px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.filter-clear-btn {
  background-color: white;
  color: #1890ff;
  border: 1px solid #1890ff;
  padding: 6px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.filter-dropdown-overlay {
  z-index: 1050;
}

.filter-dropdown-container .ant-checkbox .ant-checkbox-inner:after {
  /* border: 2px solid #000; */
}

.filter-dropdown-container .ant-checkbox-checked .ant-checkbox-inner {
  /* background-color: #fff; */
  /* border-color: #000; */
}
