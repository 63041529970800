.dashboard-container {
  position: relative;
  -webkit-box-orient: vertical;
  width: 100%;
  max-width: calc(100% - var(--sidebar-full-width));
  padding-top: calc(var(--header-height) + 46px);
  padding-bottom: 40.5328px;
  -webkit-transition: var(--t-base);
  transition: var(--t-base);
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  min-height: calc(100vh - 2.3363rem);
  padding-left: 1rem;
  padding-right: 1rem;
}

/* Time Filter Styles */
.time-filter {
  margin-bottom: 1.5rem;
  display: flex;
  height: 37px;
}

.time-btn {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: none;
  background: #fff;
  color: #000;
  font-weight: 500;
  cursor: pointer;
}

.time-btn.active {
  background: #fff;
}

/* Stats Grid Styles */
.stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.stat-card {
  background-color: #222;
  border-radius: 0.5rem;
  padding: 1.25rem;
}

.stat-title {
  font-size: 0.875rem;
  color: #fff;
  margin: 0 0 0.5rem 0;
  font-weight: normal;
}

.stat-value {
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
}

/* Charts Layout */
.charts-layout {
  display: grid;
  grid-template-columns: 1.7fr 1fr;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.chart-card-large.total-sales-over-time {
  height: 25rem; /* or 20rem or auto, depending on your preference */
}

/* Sales Breakdown Styles */
.sales-breakdown {
  background-color: #222;
  border-radius: 0.5rem;
  padding: 1.25rem;
}

.breakdown-title {
  font-size: 0.875rem;
  color: #fff;
  margin: 0 0 1rem 0;
  font-weight: normal;
}

.breakdown-items {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  overflow-y: auto;
  height: 16rem;
}

.breakdown-item {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.75rem;
  background-color: #333;
  border-radius: 0.25rem;
  color: #fff;
}

/* Chart Card Styles */
.chart-card {
  background-color: #222;
  border-radius: 0.5rem;
  padding: 1.25rem;
  height: 100%;
}
.chart-card-large {
  background-color: #222;
  border-radius: 0.5rem;
  padding: 1.25rem;
  height: 100%;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.25rem;
}

.chart-title {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  margin: 0;
  text-transform: uppercase;
}

.chart-subtitle {
  font-size: 0.9rem;
  color: #ccc;
  margin: 0.25rem 0 0;
}

.chart-date-info {
  font-size: 0.75rem;
  color: #8c8c8c;
  text-align: right;
}

/* Bottom Charts Grid */
.bottom-charts-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.bottom-charts-grid .chart-card {
  height: 21.75rem;
}
.chart-card-large {
  /* height: 20.75rem; */
}

/* Chart customization */
.chart-card canvas {
  width: 100% !important;
  height: calc(100% - 3.75rem) !important;
}
.chart-card-large canvas {
  width: 100% !important;
  height: 16rem !important;
}

.charts-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

/* Container for the timer filter area */
.timer-filter {
  display: flex; /* Place items side by side */
  align-items: center;
  gap: 8px; /* Roughly matches Tailwind's gap-2 */
  background-color: #fff;
  color: #000;
  padding: 8px;
  border-radius: 4px;
}

/* Make the antd RangePicker dark as well */
.timer-filter .ant-picker {
  background-color: #fff;
  color: #000;
  /* border: 1px solid #444; Darker border */
  border-radius: 4px; /* Matches container’s radius if desired */
}

.timer-filter .ant-picker-input input {
  background-color: #fff;
  color: #000; /* White text inside input */
}

/* Also style the dropdown calendar panel */
.timer-filter .ant-picker-dropdown {
  background-color: #333 !important;
  color: #fff;
  border: 1px solid #444;
}

.timer-filter .ant-picker-panel {
  background-color: #333 !important;
  color: #fff;
}

/* If you want the hovered date to be a lighter shade, override relevant classes */
.timer-filter .ant-picker-cell-inner:hover {
  background-color: #444;
  color: #fff;
}

/* React Select - override the dropdown container if you want matching black background */
.timer-filter .custom-select-container .react-select__menu {
  background-color: #333;
  color: #fff;
}

.no-data-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.no-data-image {
  max-width: 100%;
  max-height: 100%;
}

.ant-picker {
  background-color: #fff !important;
  /* border: 1px solid #444 !important; */
  color: #000 !important;
}

/* The input text inside the RangePicker */
.ant-picker-input input {
  background-color: #fff !important;
  /* border: 1px solid #444 !important; */
  color: #000 !important;
}

/* The dropdown panel */
.ant-picker-dropdown {
  background-color: #222 !important;
  /* border: 1px solid #444 !important; */
}
.ant-picker-panel-layout {
  background-color: #222 !important;
  color: #fff !important;
}
/* Calendar panel background */
.ant-picker-panel {
  background-color: #222 !important;
  color: #fff !important;
}

/* Calendar header (month/year) */
.ant-picker-header {
  background-color: #222 !important;
  color: #fff !important;
}

/* The arrows for changing months */
.ant-picker-header-view button {
  color: #fff !important;
}

/* Days of the week bar (Su, Mo, etc.) */
.ant-picker-panel-container .ant-picker-content th {
  color: #bbb !important;
  background-color: #222 !important;
}

/* Individual date cells */
.ant-picker-cell {
  color: #fff !important;
}
.ant-picker-cell-inner {
  background-color: transparent !important;
  color: #fff !important;
  border-radius: 4px;
}

/* Hovered date cell */
.ant-picker-cell-inner:hover {
  background-color: #333 !important;
}

/* Selected date cell */
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #555 !important;
  color: #fff !important;
}

/* Range hover states */
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-hover .ant-picker-cell-inner {
  background-color: #333 !important;
}

/* Footer area, if present */
.ant-picker-footer {
  background-color: #222 !important;
}

/* Month/year navigation buttons */
.ant-picker-month-btn,
.ant-picker-year-btn,
.ant-picker-decade-btn {
  color: #fff !important;
}
:where(.css-dev-only-do-not-override-49qm).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range:not(
    .ant-picker-cell-disabled
  ):before,
:where(.css-dev-only-do-not-override-49qm).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-disabled
  ):before,
:where(.css-dev-only-do-not-override-49qm).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-disabled
  ):before {
  background: #000000;
}

:where(.css-dev-only-do-not-override-49qm).ant-picker
  .ant-picker-input
  > input {
  font-weight: 500;
}
:where(.css-dev-only-do-not-override-49qm).ant-picker-dropdown
  .ant-picker-prev-icon,
:where(.css-dev-only-do-not-override-49qm).ant-picker-dropdown
  .ant-picker-next-icon,
:where(.css-dev-only-do-not-override-49qm).ant-picker-dropdown
  .ant-picker-super-prev-icon,
:where(.css-dev-only-do-not-override-49qm).ant-picker-dropdown
  .ant-picker-super-next-icon {
  color: #fff;
}
