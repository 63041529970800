Layout basics .app-container {
  display: flex;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  background-color: #000;
  min-height: 100vh;
  /* Add some padding so the cards and table have room at the top */
  padding-bottom: 1.25rem;
  font-family: "cutomFont" !important;
}

.page-content .ant-table-wrapper .ant-table {
  font-family: "cutomFont" !important;
}

/* Table container (applies to AntD as well) */
.table-container-payment-anlytics-report {
  margin: 1.5rem;
  background-color: #111;
  border-radius: 0.75rem;
  overflow: hidden;
}

/* ---------------------------------
   AntD Table Overrides for dark UI 
   --------------------------------- */
.table-container-payment-anlytics-report .ant-table {
  background: #000 !important;
  color: #fff !important;
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.table-container-payment-anlytics-report .ant-table-thead > tr > th {
  background-color: #000 !important;
  color: #fff !important;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.0313rem;
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1) !important;
}

/* Striped row colors using rowClassName (table-row-light/dark) */
.table-container-payment-anlytics-report
  .ant-table-tbody
  > tr.table-row-light
  > td {
  background-color: #2f2f2f !important;
  color: #fff !important;
}
.table-container-payment-anlytics-report
  .ant-table-tbody
  > tr.table-row-dark
  > td {
  background-color: #424242 !important;
  color: #fff !important;
}

/* Subtle bottom border on each row */
.table-container-payment-anlytics-report .ant-table-tbody > tr > td {
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1) !important;
}

/* Hover effect for antd */
.table-container-payment-anlytics-report .ant-table-tbody > tr:hover > td {
  background-color: #222 !important;
}

/* ------------------------
   Buttons & general styles
   ------------------------ */
.viewbtn {
  background-color: transparent;
  border: none;
  color: #373636;
  padding: 0.3125rem 0.625rem;
  cursor: pointer;
  border-radius: 0.25rem;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.875rem;
  cursor: pointer;
  transition: 0.8s;
}

.viewbtn:hover {
  color: rgb(255, 255, 255);
}

.viewbtn-view {
  color: #373636;
}

/* Status indicators */
.cansellation-report-status {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
}

.status-success {
  /* background-color: rgba(46, 204, 113, 0.2); */
  color: #2ecc71 !important;
}

.status-refund {
  /* background-color: rgba(231, 76, 60, 0.2); */
  color: #000 !important;
}

.status-process {
  /* background-color: rgba(241, 196, 15, 0.2); */
  color: #636161 !important;
}

/* ----------------
   Card styles
   ---------------- */
.summary-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.25rem;
  margin: 1.25rem; /* space around the cards */
}

.unique-card {
  background-color: #1a1a1a;
  border-radius: 0.75rem;
  padding: 1.5rem;
}

.unique-card-title {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.8125rem;
  font-weight: 500;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
}

.unique-card-value {
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
}

/* Action bar with filter/search/download */
.action-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.25rem;
}

.filter-button {
  background-color: #1a1a1a;
  border: none;
  border-radius: 50%;
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
}

.search-bar {
  position: relative;
  display: flex;
  align-items: center;
}

.search-icon {
  position: absolute;
  left: 0.625rem;
  color: rgba(255, 255, 255, 0.5);
}

.search-reports {
  background-color: #1a1a1a;
  border: none;
  border-radius: 1.25rem;
  padding: 0.5rem 0.9375rem 0.5rem 2.25rem; /* leave space for the icon */
  color: #fff;
  width: 12.5rem;
  font-size: 0.75rem;
}

.report-button {
  background-color: #1a1a1a;
  border: none;
  border-radius: 0.25rem;
  padding: 0.5rem 0.9375rem;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-size: 0.75rem;
  text-transform: uppercase;
}

/* Back button */
.back-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
  margin: 1.25rem;
  text-decoration: none;
  font-size: 1.1rem;
  letter-spacing: 0.1875rem;
  text-transform: uppercase;
}

/* Report title */
.report-title {
  font-size: 1.125rem;
  font-weight: 600;
  margin: 1.25rem;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.0313rem;
}

.reports-header {
  margin-bottom: 1.875rem;
  border-bottom: 0.0625rem solid #222;
  padding-bottom: 0.9375rem;
}
.reports-list {
  display: flex;
  flex-direction: column;
  gap: 0.9375rem;
}
.report-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.9375rem;
  background-color: #1a1a1a;
  border-radius: 0.5rem;
}
.report-number {
  width: 2.5rem;
  text-align: center;
  font-weight: 600;
}
.report-name {
  flex: 1;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.0313rem;
}

/* Responsive adjustments */
@media (max-width: 48rem) {
  .summary-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 36rem) {
  .summary-cards {
    grid-template-columns: 1fr;
  }
}

/* The outer container for your ReportsList */
.reports-list-container {
  padding: 1.5rem;
  background-color: #000;
}

/* Force the antd table to have a dark background & white text */
.reports-list-container .ant-table {
  background: #000 !important;
  color: #fff !important;
  border-color: rgba(255, 255, 255, 0.2) !important;
  border-radius: 1.5rem !important;
}

.reports-list-container .ant-table-thead > tr > th {
  background-color: #000 !important;
  color: #fff !important;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.0313rem;
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1) !important;
}

.reports-list-container .ant-table-tbody > tr.table-row-light > td {
  background-color: #9a9a9a !important; /* darker gray */
  color: #373636 !important;
}

.reports-list-container .ant-table-tbody > tr.table-row-dark > td {
  background-color: #dcdcdc !important; /* slightly lighter gray */
  color: #373636 !important;
}

/* Subtle bottom border on each row */
.reports-list-container .ant-table-tbody > tr > td {
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1) !important;
}
.reports-list-container .ant-table-wrapper {
  border-radius: 1.125rem !important;
  overflow: hidden;
  /* If you need a background so the corners show up, set it here: */
  background-color: #000;
}
.reports-list-container .ant-table-thead > tr > th,
.reports-list-container .ant-table-tbody > tr > td {
  text-align: center;
}
.table-container-payment-anlytics-report .ant-table-thead > tr > th,
.table-container-payment-anlytics-report .ant-table-tbody > tr > td {
  text-align: center;
}
.table-container-payment-anlytics-report
  .ant-table-tbody
  > tr.table-row-light
  > td {
  background-color: #9a9a9a !important; /* darker gray */
  color: #373636 !important;
}

.table-container-payment-anlytics-report
  .ant-table-tbody
  > tr.table-row-dark
  > td {
  background-color: #dcdcdc !important; /* slightly lighter gray */
  color: #373636 !important;
}

/* 
  userBookingDetailsReport.css
  Unique classes for the "User Booking Details Report" 
*/

/* Main container for the page */
.user-booking-container {
  min-height: 100vh;
  background-color: #000; /* black background */
  color: #fff;
  padding: 1.5rem;
  font-family: "cutomFont" !important;
}

/* Back button styling */
.ubdr-back-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  letter-spacing: 0.1875rem;
}
.ubdr-search-reports::placeholder {
  color: #999; /* or any color you prefer */
  font-size: 0.6875rem;
  font-weight: 500;
}
/* Action bar container */
.ubdr-action-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
}

/* Filter button, left side */
.ubdr-filter-button {
  background-color: #fff;
  border: none;
  border-radius: 50%;
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #000;
}

/* Search bar container */
.ubdr-search-bar {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 18.75rem; /* adjust as needed */
  margin: 0 1rem;
}

.ubdr-search-icon {
  position: absolute;
  left: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
}

.ubdr-search-reports {
  background-color: #fff;
  border: none;
  border-radius: 0.3125rem;
  padding: 0.5rem 0.9375rem 0.5rem 2.5rem;
  color: #000;
  width: 100%;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.0313rem;
}

/* Report button, right side */
.ubdr-report-button {
  background-color: #1a1a1a;
  border: none;
  border-radius: 0.25rem;
  padding: 0.5rem 0.9375rem;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-size: 0.75rem;
  text-transform: uppercase;
}

/* Table container for AntD */
.ubdr-table-container {
  background-color: #111; /* a slightly lighter black behind table */
  border-radius: 0.75rem;
  overflow: hidden;
  padding: 1rem;
}

/* -----------------------------
   AntD Overrides for dark table
   ----------------------------- */

/* Force the antd table wrapper to show corners, 
   especially if we want rounded corners on the table. */
.ubdr-table-container .ant-table-wrapper {
  background: #000 !important;
  border-radius: 0.75rem;
  overflow: hidden;
}

/* The table itself uses a black/dark background and white text */
.ubdr-table-container .ant-table {
  background-color: #000 !important;
  color: #fff !important;
  border-color: rgba(255, 255, 255, 0.2) !important;
}

/* Header row styling */
.ubdr-table-container .ant-table-thead > tr > th {
  background-color: #000 !important;
  color: #fff !important;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.0313rem;
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1) !important;
}

/* Dark striped rows using rowClassName=table-row-light / table-row-dark */
.ubdr-table-container .ant-table-tbody > tr.table-row-light > td {
  background-color: #2f2f2f !important; /* darker gray */
  color: #fff !important;
}

.ubdr-table-container .ant-table-tbody > tr.table-row-dark > td {
  background-color: #424242 !important; /* lighter gray */
  color: #fff !important;
}

/* Subtle bottom border for each row */
.ubdr-table-container .ant-table-tbody > tr > td {
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1) !important;
}

/* Hover effect */
.ubdr-table-container .ant-table-tbody > tr:hover > td {
  background-color: #222 !important;
}
.user-booking-container .ant-table-thead > tr > th,
.user-booking-container .ant-table-tbody > tr > td {
  text-align: center;
}
.user-booking-container .ant-table-tbody > tr.table-row-light > td {
  background-color: #9a9a9a !important; /* darker gray */
  color: #373636 !important;
}

.user-booking-container .ant-table-tbody > tr.table-row-dark > td {
  background-color: #dcdcdc !important; /* slightly lighter gray */
  color: #373636 !important;
}

/*
  CancellationReport.css
  Custom classes + AntD overrides for the
  "Request for Cancellation/Refund Report" page
*/

/* Main container for this page */
.cancellation-container {
  background-color: #000; /* black background */
  color: #fff;
  min-height: 100vh;
  padding: 1.5rem;
  font-family: "cutomFont" !important;
}

/* Back button link */
.cr-back-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.1rem;
  letter-spacing: 0.1875rem;
  margin-bottom: 1.5rem;
}

/* Action bar (filter, search, report) */
.cr-action-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

/* Filter button */
.cr-filter-button {
  background-color: #1a1a1a;
  border: none;
  border-radius: 50%;
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
}

/* Search bar container */
.cr-search-bar {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 18.75rem;
  margin: 0 1rem;
}

.cr-search-icon {
  position: absolute;
  left: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
}

.cr-search-reports {
  background-color: #1a1a1a;
  border: none;
  border-radius: 1.25rem;
  padding: 0.5rem 0.9375rem 0.5rem 2.5rem;
  color: #fff;
  width: 100%;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.0313rem;
}

/* Report download button */
.cr-report-button {
  background-color: #1a1a1a;
  border: none;
  border-radius: 0.25rem;
  padding: 0.5rem 0.9375rem;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-size: 0.75rem;
  text-transform: uppercase;
}

/* Table container */
.cr-table-container {
  background-color: #111; /* slightly lighter black behind the table */
  border-radius: 0.75rem;
  overflow: hidden;
  padding: 1rem;
}

/* 
  AntD Table Overrides 
  to set dark theme, 
  striped rows, hover effect, etc.
*/

/* Ensure the .ant-table-wrapper is also included in rounding */
.cr-table-container .ant-table-wrapper {
  background: #000 !important;
  border-radius: 0.75rem;
  overflow: auto;
}

/* Base AntD table styling: dark background, white text */
.cr-table-container .ant-table {
  background-color: #000 !important;
  color: #fff !important;
  border-color: rgba(255, 255, 255, 0.2) !important;
}

/* Header row */
.cr-table-container .ant-table-thead > tr > th {
  background-color: #000 !important;
  color: #fff !important;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.0313rem;
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1) !important;
}

/* Striped rows (table-row-light / table-row-dark) */
.cr-table-container .ant-table-tbody > tr.table-row-light > td {
  background-color: #2f2f2f !important;
  color: #fff !important;
}
.cr-table-container .ant-table-tbody > tr.table-row-dark > td {
  background-color: #424242 !important;
  color: #fff !important;
}

/* Subtle bottom border */
.cr-table-container .ant-table-tbody > tr > td {
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1) !important;
}

/* Hover effect on row */
.cr-table-container .ant-table-tbody > tr:hover > td {
  background-color: #222 !important;
}

/* -----------
   Status pills
   ----------- */
.cansellation-report-status {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
}

/* If status is "SUCCESS", color green text */
.status-success {
  color: #10b981; /* or #2ecc71 if you prefer */
}

/* If status is "REFUND", color red text */
.status-refund {
  color: #ef4444; /* or #e74c3c */
}

/* If status is "IN PROCESS", color gold text */
.status-process {
  color: #f59e0b; /* or #f1c40f */
}
.cancellation-container .ant-table-thead > tr > th,
.cancellation-container .ant-table-tbody > tr > td {
  text-align: center;
}
.cancellation-container .ant-table-tbody > tr.table-row-light > td {
  background-color: #9a9a9a !important; /* darker gray */
  color: #373636 !important;
}

.cancellation-container .ant-table-tbody > tr.table-row-dark > td {
  background-color: #dcdcdc !important; /* slightly lighter gray */
  color: #373636 !important;
}

/*
  SubscriptionReport.css
  Styles + AntD overrides for the "Subscription Report" page
*/

/* Main container for the Subscription page */
.subscription-container {
  background-color: #000; /* black background */
  color: #fff;
  min-height: 100vh;
  padding: 1.5rem;
  font-family: "cutomFont" !important;
}

/* Back button link */
.sr-back-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.1rem;
  letter-spacing: 0.1875rem;
  margin-bottom: 1.5rem;
}

/* Action bar (filter, search, report) */
.sr-action-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

/* Filter button */
.sr-filter-button {
  background-color: #1a1a1a;
  border: none;
  border-radius: 50%;
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
}

/* Search bar container */
.sr-search-bar {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 18.75rem;
  margin: 0 1rem;
}

.sr-search-icon {
  position: absolute;
  left: 0.75rem;
  color: rgba(255, 255, 255, 0.5);
}

.sr-search-reports {
  background-color: #1a1a1a;
  border: none;
  border-radius: 1.25rem;
  padding: 0.5rem 0.9375rem 0.5rem 2.5rem;
  color: #fff;
  width: 100%;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.0313rem;
}

/* Report button */
.sr-report-button {
  background-color: #1a1a1a;
  border: none;
  border-radius: 0.25rem;
  padding: 0.5rem 0.9375rem;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-size: 0.75rem;
  text-transform: uppercase;
}

/* Table container background + rounding */
.sr-table-container {
  background-color: #111; /* slightly lighter black behind table */
  border-radius: 0.75rem;
  overflow: hidden;
  padding: 1rem;
}

/* 
  AntD Table Overrides 
  for dark theme + striped rows
*/
.sr-table-container .ant-table-wrapper {
  background: #000 !important;
  border-radius: 0.75rem;
  overflow: hidden;
}

/* Base table styling: black background, white text */
.sr-table-container .ant-table {
  background-color: #000 !important;
  color: #fff !important;
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.sr-table-container .ant-table-thead > tr > th {
  background-color: #000 !important;
  color: #fff !important;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.0313rem;
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1) !important;
}

/* Zebra stripes (table-row-light / table-row-dark) */
.sr-table-container .ant-table-tbody > tr.table-row-light > td {
  background-color: #2f2f2f !important;
  color: #fff !important;
}
.sr-table-container .ant-table-tbody > tr.table-row-dark > td {
  background-color: #424242 !important;
  color: #fff !important;
}

/* Subtle bottom border for each row */
.sr-table-container .ant-table-tbody > tr > td {
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1) !important;
}

/* Hover effect on rows */
.sr-table-container .ant-table-tbody > tr:hover > td {
  background-color: #222 !important;
}

/* 
  Status colors for SUBSCRIPTION STATUS 
  "ACTIVE" or "INACTIVE"
*/
.status-active {
  color: #10b981; /* a green shade */
  font-weight: 600;
  text-transform: uppercase;
}
.status-inactive {
  color: #ef4444; /* a red shade */
  font-weight: 600;
  text-transform: uppercase;
}

.subscription-container .ant-table-thead > tr > th,
.subscription-container .ant-table-tbody > tr > td {
  text-align: center;
}
.subscription-container .ant-table-tbody > tr.table-row-light > td {
  background-color: #9a9a9a !important; /* darker gray */
  color: #373636 !important;
}

.subscription-container .ant-table-tbody > tr.table-row-dark > td {
  background-color: #dcdcdc !important; /* slightly lighter gray */
  color: #373636 !important;
}

.user-booking-container
  .ant-table-wrapper
  .ant-table-tbody
  > tr.ant-table-placeholder:hover
  > th,
.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder:hover > td,
.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder {
  background: #101010;
}

.user-booking-container .ant-empty-normal .ant-empty-description {
  color: #ffffff;
}
.cancellation-container .ant-empty-normal .ant-empty-description {
  color: #ffffff;
}
.table-container-payment-anlytics-report
  .ant-empty-normal
  .ant-empty-description {
  color: #ffffff;
}
.sr-table-container .ant-empty-normal .ant-empty-description {
  color: #ffffff;
}
.pagination-container-payment {
  margin-right: 2.25rem;
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
.ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: #000 !important;
}
